/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import foto from './static/images/Lubeck_start.JPG'
import './App.css'
import pdf from './portfolio.pdf'

function App() {


  const onResumeClick = () => {
      window.open(pdf);
  }

  return (
    <div
      className="App"
      css={css`
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <div
        className="foto"
        css={css`
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: url(${foto}) no-repeat center center fixed;
          background-size: cover;
          background-position: 0 70%;
          color: white;
          position: relative;
        `}
      >
        <div
          className="message"
          css={css`
            font-size: 2rem;
            font-weight: 600;
            position: absolute;
            top: 50%;
            right: 5rem;
          `}
        >
      <div
      onClick={ () => onResumeClick() }
      css={css`
      cursor: pointer;
      span {
        text-decoration: underline;
      }
      :hover {
                  color: black;
                }
    `}
      >
      <span>
        view portfolio
        </span>
        {' >>'}
      </div>
          <div
            className="contact"
            css={css`
              margin-top: 7rem;
              a {
                color: white;
                font-size: 1.5rem;
                font-weight: 400;
                text-decoration: none;
                :hover {
                  color: black;
                }
              }
            `}
          >
            <a href="mailto:info@camillamilenafeher.com">
            info@camillamilenafeher.com
            </a>
          </div>
        </div>
      </div>

    </div>
  )
}

export default App
